// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "bootstrap";
import { imageZoom } from "./image-magnifier.js";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// console.log("imageZoom", imageZoom);

// $(document).ready(function () {
//   // console.log("ready", imageZoom();
//   // $(".img-container").map(function (index, element) {
//   //   let img = $(element).find("img");
//   //   console.log("img-container", element);
//   //   imageZoom(
//   //     { blur: false, inner: true, tint: true },
//   //     {
//   //       src: img.attr("src"),
//   //       height: 1280,
//   //       width: 1600,
//   //     },
//   //     { src: img.attr("src"), height: 512, width: 640 },
//   //     element
//   //   );
//   // });
//   // imageZoom(
//   //   { blur: false, inner: true, tint: false },
//   //   { src: document.querySelectorAll("img")[1].src, height: 1280, width: 1600 },
//   //   { src: document.querySelectorAll("img")[1].src, height: 512, width: 640 },
//   //   document.querySelectorAll(".img-container")[1]
//   // );
//   // imageZoom(
//   //   { blur: false, inner: true, tint: false },
//   //   { src: document.querySelectorAll("img")[2].src, height: 1280, width: 1600 },
//   //   { src: document.querySelectorAll("img")[2].src, height: 512, width: 640 },
//   //   document.querySelectorAll(".img-container")[2]
//   // );
// });
